import React from "react";

import Helmet from "../../components/Helmet";

const JournalPage = () => {
  return (
    <div>
      <Helmet title={`HKSNA | Journals `} />

      <h1>Journal Page</h1>
      <ul>
        <li>Short Description List of past editors</li>
        <li> Submission guidelines </li>
        <li> Advertising instructions (link to email)</li>
        <li>
          {" "}
          Journal index Back issues pricing, shipping information, and order
          instructions (link to email)
        </li>
      </ul>
    </div>
  );
};

export default JournalPage;
