import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import marked from "marked";
import { getSingleCompetition } from "../../contentful";
import "./CompetitionsDetailsPageStyles.scss";

import { Typography, Button } from "@material-ui/core";
import Tabs from "../../components/Tabs/Tabs";

import PageTitle from "../../components/PageTitle";
import Helmet from "../../components/Helmet";
import CompetitionsApplicationModal from "../../components/CompetitionsApplicationModal/CompetitionsApplicationModal";
import Accordion from "../../components/Accordion/Accordion";
import CompetitionApplicationForm from "./CompetitionApplicationForm";
import Table from "../../components/Table/Table";
import Gallery from "../../components/Gallery/Gallery";

const CompetitionsDetailsPage = () => {
  const [comp, setComp] = useState(null);
  const [compToApply, setCompToApply] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleCompetition(id).then((data) => {
      console.log(data);
      setComp(data?.[0]?.fields);
    });
  }, [id]);

  return (
    <div className='container'>
      <Helmet title={`HKSNA | ${comp?.title} `} />
      {comp && comp.title && <PageTitle title={comp.title} />}
      {/* <Button
        style={buttonStyle}
        variant='outlined'
        color='primary'
        onClick={() => {
          setCompToApply(comp);
        }}
      >
        Apply Here
      </Button> */}
      {compToApply && (
        <CompetitionsApplicationModal
          open={compToApply}
          onClose={() => {
            setCompToApply(null);
          }}
          competition={compToApply}
        />
      )}
      <div className='competition__details'>
        {comp && comp.details && (
          <div className='competition__details__text'>
            <Typography
              dangerouslySetInnerHTML={{ __html: marked(comp.details) }}
            />
          </div>
        )}

        {comp && comp?.image && (
          <div className='competition__details__image'>
            {" "}
            <img src={comp.image.fields.file.url} />
          </div>
        )}
      </div>
      {comp?.showApplicationForm && comp?.title.includes("Jurow") && (
        <CompetitionApplicationForm />
      )}
      {comp &&
        comp.requirementsFr &&
        comp.requirementsEn &&
        [
          { header: "Requirements (EN)", content: comp.requirementsEn },
          { header: "Requirements (FR)", content: comp.requirementsFr },
        ].map((item) => (
          <Accordion header={item.header} content={item.content} />
        ))}
      {comp && comp.pastWinners && (
        <Accordion
          mainSection
          header='Past Winners'
          content={comp.pastWinners}
        />
      )}
      {comp && comp.winnersTable && <Table table={comp.winnersTable} />}
      <div>
        {comp && comp?.galleryPhoto && <Gallery images={comp.galleryPhoto} />}
      </div>
    </div>
  );
};

export default CompetitionsDetailsPage;
